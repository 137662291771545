






import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import HelpCard from "@/components/for-payments-view/HelpCard.vue";

@Component({
  components: { Page, HelpCard },
})
class Payments extends Vue {}

export default Payments;
